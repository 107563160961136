import React from "react"
import { graphql } from "gatsby"
import { SimpleGrid, Box, Text, Button } from "@chakra-ui/react"
import Markdown from "../../UI/Markdown"
import CustomLink from "../../UI/CustomLink"
import CustomImage from "../../UI/CustomImage"
import Wrapper from "../Wrapper"

const Cards = ({ data }) => {
  return (
    <Wrapper data={data.blockSettings} bg="sand">
      {data.title && (
        <Text as="h2" textAlign="center" mt="0">{data.title}</Text>
      )}
      {data.text && (
        <Box textAlign="center"><Markdown>{data.text}</Markdown></Box>
      )}
      <SimpleGrid columns={[1,2]} spacing={6} pb={5}>
      {data.items.map((item, i) => (
        <Box key={i} bg="white" position="relative" mt={10}>
          <CustomImage img={item.image} />
          <Box p={6} pb={10}>
            {item.cardTitle && (
              <Text as="h3" mt="0" textAlign="center">{item.cardTitle}</Text>
            )}
            {item.cardText && (
              <Box textAlign="center" maxW="320px" mx="auto"><Markdown>{item.cardText}</Markdown></Box>
            )}
            {item.cta && (
              <Box
              pos="absolute"
              top="100%"
              left="50%"
              transform="translate(-50%,-50%)"
              bg="white"
              sx={{
                borderRadius: "40px"
              }}>
                <CustomLink href={item.cta.href}>
                  <Button colorScheme="brand" disabled={item.cta.href ? false : true}>{item.cta.label}</Button>
                </CustomLink>
              </Box>
            )}
          </Box>
        </Box>
      ))}
      </SimpleGrid>
    </Wrapper>
  );
};

export default Cards;

export const query = graphql`
  fragment CardsBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    text
    items {
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 1000
          )
        }
      }
      cardTitle
      cardText
      cta {
        label
        href
      }
    }
  }
`;
